import Layout from 'components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function Europeanfunds() {
	return (
		<Layout hideLanguage>
			<div className='w-95/100 sm:w-70/100 m-auto pt-50'>
				<StaticImage src='../../../assets/footer/ue-flag.png' alt="" placeholder='none' />

				<div className='sm:text-18'>
					<p className='my-40 sm:my-60'>FQ Prosta Spółka Akcyjna realizuje projekt dofinansowany z Funduszy Europejskich</p>

					<p className='my-24 sm:leading-30 text-justify'>
            „Finteq” - projekt badawczo rozwojowy, którego celem jest opracowanie algorytmu, który w sposób 
            zautomatyzowany pozwoli na kwotowanie ofert na platformie, ich selekcję oraz 
            przedstawienie/udostępnienie grupie uczestników obrotu gospodarczego, którzy spełniają określone 
            kryteria w parametrach kwerendy.
					</p>

					<p className='my-24'>Wartość projektu: 1 034 123,00 zł</p>

					<p className='mb-24'>Wysokość dofinansowania: 825 250,00 zł</p>
				</div>
			</div>
		</Layout>
	);
}